/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
@keyframes gnavi_sub {
	0% {
		opacity: 0;
		transform: scale(0.9) translateY(-15px);
	}
	100% {
		transform: scale(1) translateY(0);
	}
}

@keyframes ease_out_back {
	0% {
		transform: scale(0.3);
	}
	60% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes on_opacity {
	0% {
		opacity: 0;
		display: none;
	}
	100% {
		opacity: 1;
	}
}

@keyframes hero_area {
	0% {
		opacity: 0;
		display: none;
		transform: scale(0.8);
	}
	20% {
		transform: scale(1);
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes pop-upwards {
	0% {
		transform: matrix(0.97, 0, 0, 1, 0, 12);
		opacity: 0;
	}
	20% {
		transform: matrix(0.99, 0, 0, 1, 0, 2);
		opacity: .7;
	}
	40% {
		transform: matrix(1, 0, 0, 1, 0, -1);
		opacity: 1;
	}
	70% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
	100% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
}

@keyframes pop-downwards {
	0% {
		transform: matrix(0.97, 0, 0, 1, 0, -12);
		opacity: 0;
	}
	20% {
		transform: matrix(0.99, 0, 0, 1, 0, -2);
		opacity: .7;
	}
	40% {
		transform: matrix(1, 0, 0, 1, 0, 1);
		opacity: 1;
	}
	70% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
	100% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
}

@keyframes card {
	0% {
		transform: rotateX(-90deg) rotateY(-20deg) rotateZ(10deg) scale(0.5) translateY(200px);
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/**************************
index
*/
h3 {
	border-bottom: 0 none;
}

.hero_area {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	animation-name: _on_opacity;
	animation-duration: 1.5s;
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-delay: .5s;
	overflow: hidden;
}

@media only screen and (min-width: 768px) {
	.hero_area {
		margin-top: 10px;
		margin-bottom: 10px;
		height: 360px;
	}
}

.hero_area .container .hero_dots,
.hero_area .container .hero_arrows button {
	pointer-events: auto;
}

.hero_area-init {
	animation-name: none;
}

.hero_area.slick-dotted.slick-slider {
	margin-bottom: 0;
}

.hero_area .slick-dots {
	box-sizing: border-box;
}

.hero_text {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 11;
	pointer-events: none;
}

@media only screen and (min-width: 768px) {
	.hero_text {
		transform: translate(-50%, -53%);
		width: 770px;
		height: 100px;
	}
}

@media only screen and (max-width: 767px) {
	.hero_text {
		width: 210px;
		height: 60px;
		transform: translate(-50%, -50%);
	}
}

.hero_text img {
	display: block;
	max-width: 100%;
}

.hero a:hover {
	text-decoration: none;
	opacity: 0.7;
	transition: 0.2s opacity;
}

@media only screen and (min-width: 768px) {
	.hero img {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.iclinic {
	text-align: center;
	margin-bottom: 45px;
}

@media only screen and (min-width: 768px) {
	.iclinic {
		padding-top: 33px;
	}
}

@media only screen and (max-width: 767px) {
	.iclinic {
		padding-top: 20px;
	}
}

.iclinic h3 {
	margin: auto;
}

@media only screen and (min-width: 768px) {
	.iclinic h3 {
		width: 250px;
		height: 30px;
		margin-bottom: 7px;
	}
}

@media only screen and (max-width: 767px) {
	.iclinic h3 {
		width: 180px;
		margin-bottom: 5px;
	}
}

.iclinic h3 img {
	display: block;
}

.iclinic .sub {
	color: #684d3c;
	line-height: 1.6;
}

@media only screen and (min-width: 768px) {
	.iclinic .sub {
		font-size: 0.875rem;
		margin-bottom: 41px;
	}
}

@media only screen and (max-width: 767px) {
	.iclinic .sub {
		font-size: 0.75rem;
		margin-bottom: 15px;
	}
}

.inews {
	background-color: #f7f9d3;
}

@media only screen and (min-width: 768px) {
	.inews {
		padding-bottom: 60px;
	}
}

@media only screen and (max-width: 767px) {
	.inews {
		padding-bottom: 30px;
	}
	.inews > .container {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.inews::before {
	content: '';
	display: block;
	width: 100%;
	height: 10px;
	background: url("../img/index/inews_border.png") 0 0 repeat-x transparent;
}

@media only screen and (min-width: 768px) {
	.inews__head {
		padding-top: 35px;
	}
}

@media only screen and (max-width: 767px) {
	.inews__head {
		padding-top: 20px;
	}
}

.inews__head h3 {
	margin: auto;
}

@media only screen and (min-width: 768px) {
	.inews__head h3 {
		width: 112px;
		height: 28px;
		margin-bottom: 37px;
	}
}

@media only screen and (max-width: 767px) {
	.inews__head h3 {
		width: 85px;
		margin-bottom: 7px;
	}
}

@media only screen and (min-width: 768px) {
	.inews__body {
		display: flex;
	}
	.inews__body__1 {
		width: 680px;
	}
	.inews__body__2 {
		padding-left: 40px;
	}
}

.inews .news_list {
	margin-bottom: 30px;
}

.ibanner_list a:hover {
	text-decoration: none;
	opacity: 0.7;
	transition: 0.2s opacity;
}

.ibanner_list a,
.ibanner_list img {
	display: block;
	width: 260px;
}

.ibanner_list li + li {
	margin-top: 15px;
}

@media only screen and (max-width: 767px) {
	.ibanner_list a {
		margin: auto;
	}
}

.iform {
	padding: 40px 0;
}

.iform a {
	margin: auto;
	max-width: 100%;
}

.iform a:hover {
	text-decoration: none;
	opacity: 0.7;
	transition: 0.2s opacity;
}

.iform a,
.iform img {
	display: block;
	width: 380px;
}

@media only screen and (min-width: 768px) {
	.iform {
		padding: 40px 0;
	}
}

@media only screen and (max-width: 767px) {
	.iform {
		padding: 20px 0;
	}
}

.imap {
	border-top: 3px solid #d9e021;
}

.imap .gmap {
	margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
	.imap .gmap {
		height: 300px;
	}
}

@media only screen and (max-width: 767px) {
	.imap .gmap {
		height: 230px;
	}
}
