@charset "utf-8";
@import 'import/var';
@import 'import/animation';
// @import 'sprite/index';

// .test {
// 	position: absolute;
// 	top: 45px;
// 	left: 50%;
// 	transform: translateX(-50%);
// 	width: 320px;
// 	height: 2080px;
// 	z-index: 100000;
// 	opacity: .2;
// }

/**************************
index
*/

h3 {
	border-bottom: 0 none;
}

.hero_area {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	animation-name: _on_opacity;
	animation-duration: 1.5s;
	animation-timing-function: $easeOutCubic;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-delay: .5s;
	overflow: hidden;

	@media only screen and (min-width: 768px) {
		margin-top: 10px;
		margin-bottom: 10px;
		height: 360px;
	}

	.container {
		// pointer-events: none;
		.hero_dots,
		.hero_arrows button {
			pointer-events: auto;
		}
		// .hero_arrows {
		// 	width: 100%;
		// }
	}

	&-init {
		animation-name: none;
	}

	&.slick-dotted.slick-slider {
		margin-bottom: 0;

	}

	.slick-dots {
		box-sizing: border-box;
	}
}

.hero_text {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 11;
	pointer-events: none;
	@media only screen and (min-width: 768px) {
		transform: translate(-50%, -53%);
		width: 770px;
		height: 100px;
	}
	@media #{$sp} {
		width: 210px;
		height: 60px;
		transform: translate(-50%, -50%);
	}

	img {
		display: block;
		max-width: 100%;
	}

	// .hero_area-init & {
	// 	opacity: 0;
	// }
	// .hero_area-inited & {
	// 	transition: none;
	// }
}

.hero {
	a {
		@include on_opacity;
	}
	@media #{$pc} {
		img {
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}

.iclinic {
	text-align: center;
	margin-bottom: 45px;
	@media only screen and (min-width: 768px) {
		padding-top: 33px;
	}
	@media only screen and (max-width: 767px) {
		padding-top: 20px;
	}
	h3 {
		margin: auto;
		@media only screen and (min-width: 768px) {
			width: 250px;
			height: 30px;
			margin-bottom: 7px;
		}
		@media only screen and (max-width: 767px) {
			width: 180px;
			margin-bottom: 5px;
		}
		img {
			display: block;;
		}
	}
	.sub {
		color: palette('brown', '1');
		line-height: 1.6;

		@media only screen and (min-width: 768px) {
			font-size: $fontsize14;
			margin-bottom: 41px;
		}
		@media only screen and (max-width: 767px) {
			font-size: $fontsize12;
			margin-bottom: 15px;
		}
	}
}

.inews {
	background-color: #f7f9d3;

	@media only screen and (min-width: 768px) {
		padding-bottom: 60px;
	}
	@media only screen and (max-width: 767px) {
		padding-bottom: 30px;

		> .container {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 10px;
		background: url('../img/index/inews_border.png') 0 0 repeat-x transparent;
	}
	&__head {
		@media only screen and (min-width: 768px) {
			padding-top: 35px;
		}
		@media only screen and (max-width: 767px) {
			padding-top: 20px;
		}
		h3 {
			margin: auto;
			@media only screen and (min-width: 768px) {
				width: 112px;
				height: 28px;
				margin-bottom: 37px;
			}
			@media only screen and (max-width: 767px) {
				width: 85px;
				margin-bottom: 7px;
			}
		}
	}
	&__body {
		@media only screen and (min-width: 768px) {
			display: flex;
			&__1 {
				width: 680px;
			}
			&__2 {
				padding-left: 40px;
			}
		}
	}
	.news_list {
		margin-bottom: 30px;
	}
}

.ibanner_list {
	a {
		@include on_opacity;;
	}
	a,
	img {
		display: block;
		width: 260px;
	}
	li + li {
		margin-top: 15px;
	}
	@media only screen and (max-width: 767px) {
		a {
			margin: auto;
		}
	}
}

.iform {
	padding: 40px 0;
	a {
		@include on_opacity;
		margin: auto;
		max-width: 100%;
	}
	a,
	img {
		display: block;
		width: 380px;
	}

	@media only screen and (min-width: 768px) {
		padding: 40px 0;
	}
	@media only screen and (max-width: 767px) {
		padding: 20px 0;
	}
}

.imap {
	border-top: 3px solid palette('green', '2');

	.gmap {
		margin-bottom: 0;

		@media only screen and (min-width: 768px) {
			height: 300px;
		}
		@media only screen and (max-width: 767px) {
			height: 230px;
		}
	}
}

